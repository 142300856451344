const convertTime12to24 = (time12h) => {
	const [time] = time12h.split(' ');

	let [hours] = time.split(':');
	if (hours === '12') {
		hours = '00';
	}

	const last2 = time12h.slice(-2);

	if (last2 === 'PM') {
		hours = parseInt(hours, 10) + 12;
	}
	return hours;
};

document.querySelectorAll('.ct__open--hours').forEach(function (selector) {
	const openHour = document.querySelector('.hours-status').getAttribute('data-open');
	const closeHour = document.querySelector('.hours-status').getAttribute('data-closed');

	const open = convertTime12to24(openHour);
	const close = convertTime12to24(closeHour);

	const now = new Date();
	const currentTime = now.getHours();

	const width = window.innerWidth;
	const width2 = selector.clientWidth;

	const openStringDesktop = selector.querySelector('.open__time.desktop');
	const openStringMobile = selector.querySelector('.open__time.mobile');
	const closeStringHeader = selector.querySelector('.close__time.header');

	if (openStringDesktop) {
		openStringDesktop.style.display = 'none';
	}

	if (openStringMobile) {
		openStringMobile.style.display = 'none';
	}

	if (closeStringHeader) {
		closeStringHeader.style.display = 'none';
	}

	if (width < 480 || width2 < 480) {
		if (openStringMobile && closeStringHeader) {
			if (currentTime >= open && currentTime <= close) {
				openStringMobile.style.display = 'inline';
				closeStringHeader.style.display = 'none';
			} else {
				openStringMobile.style.display = 'none';
				closeStringHeader.style.display = 'inline';
			}
		}
	} else if (openStringDesktop && closeStringHeader) {
		if (currentTime >= open && currentTime <= close) {
			openStringDesktop.style.display = 'inline';
			closeStringHeader.style.display = 'none';
		} else {
			openStringDesktop.style.display = 'none';
			closeStringHeader.style.display = 'inline';
		}
	}
});

function toggleElement(buttonSelector, toggledElement, className, hover = false) {
	const button = document.querySelector(buttonSelector);
	const elements = document.querySelectorAll(toggledElement);

	if (button && elements) {
		button.addEventListener('click', function () {
			elements.forEach(function (element) {
				if (element.classList.contains(className)) {
					element.classList.remove(className);
					button.classList.remove(className);
				} else {
					element.classList.add(className);
					button.classList.add(className);

					if (toggledElement === '#mobileShopNav') {
						const other = document.querySelector('.ct__menu--main-mobile');
						other.classList.remove('mobile-menu--active');
					} else if (toggledElement === '.ct__menu--main-mobile') {
						const other = document.querySelector('#mobileShopNav');
						other.classList.remove('menu--active');
					}
				}
			});
		});

		button.addEventListener('focus', function () {
			button.addEventListener('keyup', function (e) {
				if (e.keyCode === 13 || e.keyCode === 32) {
					elements.forEach(function (element) {
						if (element.classList.contains(className)) {
							element.classList.remove(className);
							button.classList.remove(className);
						} else {
							element.classList.add(className);
							button.classList.add(className);

							if (toggledElement === '#mobileShopNav') {
								const other = document.querySelector('.ct__menu--main-mobile');
								other.classList.remove('mobile-menu--active');
							} else if (toggledElement === '.ct__menu--main-mobile') {
								const other = document.querySelector('#mobileShopNav');
								other.classList.remove('menu--active');
							}
						}
					});
				}
			});
		});

		if (hover) {
			button.addEventListener(
				'mouseover',
				function () {
					setTimeout(function () {
						elements.forEach(function (element) {
							if (element.classList.contains(className)) {
								button.classList.remove(className);
							} else {
								setTimeout(function () {
									element.classList.add(className);
									button.classList.add(className);
								}, 200);
							}
						});
					});
				},
				300,
			);

			elements.forEach(function (element) {
				element.addEventListener('mouseleave', function () {
					if (element.classList.contains(className)) {
						setTimeout(function () {
							element.classList.remove(className);
							button.classList.remove(className);
						}, 200);
					}
				});
			});
		}
	}
}

toggleElement('#mallMenuToggle', '.ct__header--mobile-extra', 'active');
toggleElement('#mallMenuToggle', '#mobileShopNav', 'menu--active');

const shopToggleMobile = document.querySelector('#mobileShopToggle');
const mallMenuToggle = document.querySelector('#mallMenuToggle');

if (shopToggleMobile) {
	shopToggleMobile.addEventListener('click', function () {
		const svgs = mallMenuToggle.querySelectorAll('svg');
		mallMenuToggle.classList.remove('mobile-menu--active');
		svgs.forEach(function (svg) {
			svg.classList.remove('mobile-menu--active');
		});
	});
}

toggleElement('#hoursToggleMobile', '.ct__header--hours .button--capacity', 'capacity--active');
toggleElement('#capacityToggle', '#mobileCapacity', 'capacity--active');
toggleElement('#hoursToggleMobile', '.ct__header--hours--all', 'menu--active');

const hoursToggle = document.querySelector('#hoursToggleMobile');
const capacityToggle = document.querySelector('#capacityToggle');
const mobileCapacity = document.querySelector('#mobileCapacity');
const mobileHoursAll = document.querySelector('#hoursToggleMobile .ct__header--hours--all');

if (hoursToggle) {
	hoursToggle.addEventListener('click', function () {
		if (mobileCapacity.classList.contains('capacity--active')) {
			mobileCapacity.classList.remove('capacity--active');
			capacityToggle.classList.remove('capacity--active');
		}
	});
}

if (capacityToggle) {
	capacityToggle.addEventListener('click', function () {
		if (mobileHoursAll.classList.contains('menu--active')) {
			mobileHoursAll.classList.remove('menu--active');
			const hoursBtn = hoursToggle.querySelector('.button--capacity');
			hoursBtn.classList.remove('capacity--active');
		}
	});
}

document
	.querySelectorAll('.ct__menu--main-mobile .menu-item-has-children')
	.forEach(function (accordion) {
		const subMenu = accordion.querySelector('.sub-menu');
		// const parentLink = accordion.querySelector('a');

		accordion.addEventListener('click', function () {
			document.querySelectorAll('.menu-item-has-children .sub-menu').forEach(function (menu) {
				menu.style.display = 'none';
			});

			// parentLink.addEventListener('click', function () {
			// 	const url = parentLink.getAttribute('href');
			// });

			if (subMenu.getAttribute('data-collapsed') === 'true') {
				subMenu.style.display = 'block';
				subMenu.setAttribute('data-collapsed', 'false');
			} else {
				subMenu.style.display = 'none';
				subMenu.setAttribute('data-collapsed', 'true');
			}

			return false;
		});
	});
